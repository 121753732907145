import React from "react";

const Closure = ({ children }) => {
  return (
    <div className="container">
      <h2
        className="is-size-3 page-title-font has-text-centered"
        style={{
          border: "5px solid red",
          borderRadius: "20px",
          backgroundColor: "#ffa099",
        }}
      >
        {children}
      </h2>
    </div>
  );
};

export default Closure;
