import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Closure from "../components/Closure";
import { HTMLContent } from "../components/Content";
import { ProductList } from "../components/ProductList";

export const ProductsPageTemplate = ({ products, colours }) => {
  return (
    <div>
      <h1 className="is-size-2 is-hidden-tablet full-width-logo page-title-font">
        Products
      </h1>
      <section className="is-hidden-touch">
        <div
          className="full-width-image-container margin-top-0 is-hidden-mobile'"
          style={{
            backgroundImage: `url('/img/page-backgrounds/products.jpg')`,
          }}
        >
          <h1
            className="is-hidden-mobile page-title-font highlight"
            style={{
              padding: "4rem",
              color: "black",
            }}
          >
            Products
          </h1>
        </div>
      </section>
      <section>
        {/* <Closure>
          Please be aware that Lonely Lentil will be closed from Tuesday 28th
          May to Monday 17th June 2024. We’re off on a holiday!
        </Closure> */}
      </section>
      <section>
        <div className="container">
          <div className="columns">
            <div className="column ">
              <div className="section">
                <br />
                <div className="tile is-ancestor">
                  <div className="tile is-parent is-vertical">
                    <div className="tile has-text-centered is-child box">
                      <p className="title tile-title">
                        1. Make yourself a list
                      </p>
                      <p>
                        Explore our list below and decide which products you'd
                        like to purchase. No minimum amount!
                      </p>
                      <br />
                      <p>
                        If you'd like additional information, tips or advice on
                        any of the products please get in touch!
                      </p>
                    </div>
                  </div>

                  <div className="tile  is-parent">
                    <div className="tile has-text-centered is-child box">
                      <p className="title tile-title">2. Send us your list</p>

                      <p>
                        You can contact us via Facebook or email with the
                        buttons below or Facebook Messenger by using the icon on
                        the bottom right of the screen.{" "}
                      </p>
                      <br />
                      <p>
                        When we receive your list we will advise you on the
                        available delivery days for your area.
                      </p>
                      <br />
                      <p>
                        If you need to amend your list just send us a message,
                        we won't send a payment link until your delivery date.
                      </p>
                      <br />
                      <span>
                        <a
                          className="button is-light is-large title tile-title is-info"
                          href="https://m.me/lonelylentil"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Facebook
                        </a>
                        &nbsp;
                        <a
                          className="button is-light is-large title tile-title is-info"
                          href="mailto:info@lonelylentil.co.uk?Subject=Hello!"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Email
                        </a>
                      </span>
                      <br />
                    </div>
                  </div>
                  <div className="tile  is-parent">
                    <div className="tile has-text-centered is-child box">
                      <p className="title tile-title">3. Payment</p>
                      <p>
                        On the day before delivery, you will receive a secure
                        payment link via email, text or Facebook (depending on
                        your preferred contact method).{" "}
                      </p>
                      <br />
                      <p>You will also receive an itemised receipt.</p>
                    </div>
                  </div>
                  <div className="tile  is-parent">
                    <div className="tile has-text-centered is-child box">
                      <p className="title tile-title">4. Delivery</p>
                      <p>
                        If you’re not home on your delivery day - no worries!
                        Please leave a container (bag, coolbag or box, ideally
                        weatherproof) on your doorstep. We will transfer your
                        order into your container.
                      </p>

                      <br />
                      <p>
                        Please note we don't have set delivery times. However,
                        if you require and ETA or any special arrange please
                        contact us.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="columns">
              <div className="column ">
                <hr />
                <ProductList products={products} colours={colours} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

ProductsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  products: PropTypes.array,
};

const ProductsPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const products = data.allProductMasterListXlsxProducts.edges.map((p, i) => {
    const shapedProduct = {
      productName: p.node.product,
      price: p.node.price,
      unit: p.node.unit,
      organic: p.node.organic === "Y" ? true : false,

      notes: p.node.notes,
      category: p.node.category,
      priceNotes: p.node.price_notes.replace(/(\r\n|\n|\r)/gm, "<br />"),
      productNotes: p.node.product_notes.replace(/(\r\n|\n|\r)/gm, "<br />"),
      allergens: {
        gluten: p.node.gluten === "Y" ? true : false,
        celery: p.node.celery === "Y" ? true : false,
        caffeine_free: p.node.caffeine_free === "Y" ? true : false,
        crustaceans: p.node.crustaceans === "Y" ? true : false,
        eggs: p.node.eggs === "Y" ? true : false,
        fish: p.node.fish === "Y" ? true : false,
        lupin: p.node.lupin === "Y" ? true : false,
        milk: p.node.milk === "Y" ? true : false,
        molluscs: p.node.moluscs === "Y" ? true : false,
        mustard: p.node.mustard === "Y" ? true : false,
        peanut: p.node.peanut === "Y" ? true : false,
        sesame: p.node.sesame === "Y" ? true : false,
        soya: p.node.soya === "Y" ? true : false,
        treenut: p.node.treenut === "Y" ? true : false,
        sulfur: p.node.sulfur === "Y" ? true : false,
      },
    };
    return shapedProduct;
  });
  const colours = data.allProductMasterListXlsxColours.edges.map((c, i) => {
    const shapedColour = {
      category: c.node.category,
      colour: c.node.colour,
    };
    return shapedColour;
  });

  return (
    <Layout>
      <ProductsPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        products={products}
        colours={colours}
      />
    </Layout>
  );
};

ProductsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ProductsPage;

export const ProductsPageQuery = graphql`
  query ProductsPage($id: String!) {
    allProductMasterListXlsxProducts {
      edges {
        node {
          id
          price
          product
          unit
          category
          organic
          caffeine_free
          gluten
          celery
          crustaceans
          eggs
          fish
          lupin
          milk
          molluscs
          mustard
          peanut
          sesame
          soya
          treenut
          sulfur
          price_notes
          product_notes
        }
      }
    }
    allProductMasterListXlsxColours {
      edges {
        node {
          category
          colour
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
