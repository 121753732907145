import React from "react";

import glutenIcon from "./gluten.png";
import celeryIcon from "./celery.png";
import crustaceansIcon from "./crustaceans.png";
import eggsIcon from "./eggs.png";
import fishIcon from "./fish.png";
import lupinIcon from "./lupin.png";
import milkIcon from "./milk.png";
import molluscsIcon from "./molluscs.png";
import mustardIcon from "./mustard.png";
import peanutIcon from "./peanut.png";
import sesameIcon from "./sesame.png";
import soyaIcon from "./soya.png";
import treenutIcon from "./treenut.png";
import caffeineFree from "./caffeine_free.png";
import sulfur from "./so2.png";

const Icon = ({ src, alt }) => (
  <img src={src} alt={alt} style={{ maxHeight: 30, verticalAlign: "middle" }} />
);

export const AllergenLegend = () => (
  <div>
    <p style={{ textAlign: "center", fontWeight: "bold", color: "#bf3319" }}>
      Lonely Lentil is careful in trying to avoid cross-contamination but due to
      the nature of selling unpackaged goods we cannot guarantee any of our
      products are allergen-free. <br /> All products are packed in an
      environment where peanuts, gluten, nuts, mustard, celery and soya are
      present. <br />
      Nutritional information for all products available on request.
    </p>
    <br />
    <div style={{ textAlign: "center", width: "100%" }}>
      <span style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}>
        Organic{" "}
        <span className="organic" style={{ height: "50px" }}>
          O
        </span>
      </span>
      <span style={{ marginLeft: 15, whiteSpace: "nowrap" }}>
        Caffeine Free <Icon src={caffeineFree} alt="Gluten" />
      </span>
      <span style={{ marginLeft: 15, whiteSpace: "nowrap" }}>
        Gluten <Icon src={glutenIcon} alt="Gluten" />
      </span>
      <span style={{ marginLeft: 15, whiteSpace: "nowrap" }}>
        Soya <Icon src={soyaIcon} alt="Soya" />
      </span>
      <span style={{ marginLeft: 15, whiteSpace: "nowrap" }}>
        Mustard <Icon src={mustardIcon} alt="Mustard" />
      </span>
      <span style={{ marginLeft: 15, whiteSpace: "nowrap" }}>
        Nuts <Icon src={treenutIcon} alt="Tree nuts" />
      </span>
      <span style={{ marginLeft: 15, whiteSpace: "nowrap" }}>
        Sesame <Icon src={sesameIcon} alt="Sesame" />
      </span>
      <span style={{ marginLeft: 15, whiteSpace: "nowrap" }}>
        Peanuts <Icon src={peanutIcon} alt="Peanuts" />
      </span>
      <span style={{ marginLeft: 15, whiteSpace: "nowrap" }}>
        Sulfur Dioxide <Icon src={sulfur} alt="Sulfur" />
      </span>
    </div>
  </div>
);

export const Allergens = ({ data }) => (
  <div style={{ textAlign: "center" }}>
    {data.gluten ? <Icon src={glutenIcon} alt="Gluten" /> : null}
    {data.caffeine_free ? (
      <Icon src={caffeineFree} alt="Caffeine Free" />
    ) : null}
    {data.soya ? <Icon src={soyaIcon} alt="Soya" /> : null}
    {data.celery ? <Icon src={celeryIcon} alt="Celery" /> : null}
    {data.crustaceans ? <Icon src={crustaceansIcon} alt="Crustaceans" /> : null}
    {data.eggs ? <Icon src={eggsIcon} alt="Eggs" /> : null}
    {data.fish ? <Icon src={fishIcon} alt="Fish" /> : null}
    {data.lupin ? <Icon src={lupinIcon} alt="Lupins" /> : null}
    {data.milk ? <Icon src={milkIcon} alt="Milk" /> : null}
    {data.molluscs ? <Icon src={molluscsIcon} alt="Molluscs" /> : null}
    {data.mustard ? <Icon src={mustardIcon} alt="Mustard" /> : null}
    {data.peanut ? <Icon src={peanutIcon} alt="Peanuts" /> : null}
    {data.sesame ? <Icon src={sesameIcon} alt="Sesame" /> : null}
    {data.treenut ? <Icon src={treenutIcon} alt="Tree Nuts" /> : null}
    {data.sulfur ? <Icon src={sulfur} alt="Sulfur" /> : null}
  </div>
);
