import React from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useExpanded,
} from "react-table";
import styled from "styled-components";
import matchSorter from "match-sorter";
import { Allergens, AllergenLegend } from "./allergens/Allergens";

const Styles = styled.div`
  padding: 1rem;

  .firstColumn {
    max-width: 200px;
  }

  .resetButton:hover {
    background-color: #ffd375;
  }
  .resetButton:focus {
    background-color: #fae0a7;
    outline: none;
  }

  .table-header {
    font-family: "Bangers", sans-serif;
    font-weight: normal !important;
    font-size: 1.2em !important;
  }

  .center {
    text-align: center;
  }

  .organic {
    color: #259e1c;
    font-weight: bold;
  }

  .gf {
    color: #1c5d9e;
    font-weight: bold;
  }

  .specialOffer {
    padding: 10px;
    background-image: linear-gradient(
      45deg,
      #d2e6cf 25%,
      #f5ebc9 25%,
      #f5ebc9 50%,
      #d2e6cf 50%,
      #d2e6cf 75%,
      #f5ebc9 75%,
      #f5ebc9 100%
    );
    background-size: 84.85px 84.85px;
    border-radius: 10px;
    border: 2px solid #73ad21;
  }

  .specialOffer > p {
    font-size: 150%;
  }

  table {
    border-spacing: 0;
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    tbody > tr:hover {
      filter: brightness(85%);
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} products...`}
    />
  );
}

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue || "All"}
      onChange={(e) => {
        setFilter(e.target.value);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

function Table({ columns, data, colours }) {
  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ["organic", "gf", "notes", "priceNotes"],
      },
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded
  );

  const renderRowSubComponent = React.useCallback(({ row }) => {
    console.log(row);
    return (
      <p dangerouslySetInnerHTML={{ __html: row.original.productNotes }}></p>
    );
  }, []);
  return (
    <div>
      <div style={{ paddingBottom: "2em" }} className="has-text-centered">
        <span className="  title tile-title">
          Click on the headers to sort products!
        </span>
        <span style={{ paddingLeft: "5em" }}>
          <button
            onClick={() => setAllFilters([])}
            className="button is-info is-light tile-title"
          >
            Clear filters
          </button>
        </span>
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th>
                  <span
                    className="table-header"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {" "}
                    {column.render("Header")}
                  </span>
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            const findColour = (colours, category) => {
              for (var i = 0; i < colours.length; i++) {
                if (colours[i].category === category) {
                  return colours[i].colour;
                }
              }
            };

            const colour = findColour(colours, row.original.category);

            return (
              <React.Fragment {...row.getRowProps()}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        style={{ backgroundColor: colour }}
                        {...cell.getCellProps({
                          className: cell.column.className,
                        })}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export const ProductList = ({ products, colours }) => {
  const data = React.useMemo(() => products, []);

  const columns = React.useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => {
          if (row.original.productNotes !== "") {
            return (
              <span {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? (
                  <span class="icon">
                    <i class="fas fa-window-close"></i>
                  </span>
                ) : (
                  <span class="icon">
                    <i class="fas fa-info-circle"></i>
                  </span>
                )}
              </span>
            );
          } else {
            return null;
          }
        },
      },
      {
        Header: "Product Name",
        accessor: "productName",
        className: "firstColumn",
        Cell: ({ value, row }) => {
          return (
            <span>
              <span style={{ fontWeight: "bold" }}>{value} </span>
              <span className="organic">{row.original.organic ? "O" : ""}</span>
              <br />
            </span>
          );
        },
      },
      {
        Header: "Price",
        accessor: "price",
        className: "firstColumn",
        Cell: ({ value, row }) => (
          <span>
            {"£" + (Math.round(value * 100) / 100).toFixed(2)}
            <br />
            <span style={{ fontStyle: "italic" }}>
              {row.original.priceNotes}
            </span>
          </span>
        ),
        disableFilters: true,
      },
      {
        Header: "Unit",
        accessor: "unit",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Organic",
        accessor: "organic",
        className: "center",
        disableFilters: true,
        disableSortBy: true,
      },

      {
        Header: "Notes",
        accessor: "notes",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: () => <div style={{ textAlign: "center" }}>Allergens</div>,
        accessor: "allergens",
        Cell: ({ value, row }) => (
          <span>
            <Allergens data={row.original.allergens} />
          </span>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Category",
        accessor: "category",
        Filter: SelectColumnFilter,
        filter: "equals",
      },

      {
        Header: "Price Notes",
        accessor: "priceNotes",
        disableFilters: true,
      },
    ],
    []
  );

  return (
    <Styles>
      <AllergenLegend />
      <br />
      <Table columns={columns} data={data} colours={colours} />
    </Styles>
  );
};

// <SpecialOffer colour = '#30a65f' title =
// "Festive baking offer!" text = "Order at least: 500g of dried fruits (any combination you like) and 500g sugar and get 10% off of these ingredients.\nAdd some nuts and/or festive spices in the same order and get 10% off of those too! \n I&#39;m getting into the festive spirit early this year!"/>
